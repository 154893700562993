.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.White-Color-Text {
  color: #FFFFFF;
}

.Yellow-Color-Text {
  color: #F9B708;
}

.Black-Color-Text {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: poppinsBold;
  src: url("../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppinsExtraBold;
  src: url("../public/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: poppinsSemiBold;
  src: url("../public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: poppinsRegular;
  src: url("../public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: poppinsLight;
  src: url("../public/fonts/Poppins-Light.ttf");
}

.poppinsBold {
  font-family: poppinsBold;
}

.poppinsExtraBold {
  font-family: poppinsExtraBold;
}

.poppinsSemiBold {
  font-family: poppinsSemiBold;
}

.poppinsRegular {
  font-family: poppinsRegular;
}

.poppinsLight {
  font-family: poppinsLight;
}

.textCenter {
  text-align: "center";
}

.slick-slide > div {
  margin: 0 10px;
}
 
.slick-list {
  margin: 0 -10px;
}
 
.slick-slide *:focus{
  outline: none;
}
